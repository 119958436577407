



































import { Prop, PropSync, Vue, Watch} from 'vue-property-decorator'
import Component from 'vue-class-component';
import { Usuario } from '@/models';
import { FindUsuarioUseCase } from '@/usecases';
import axios, {  CancelTokenSource } from 'axios';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import ListaDeUsuarios from '../usuario/ListaDeUsuarios.vue';

@Component({
	components: {
		ListaDeUsuarios,
	},
})
export default class BuscaDeVendedor extends Vue{
	@PropSync('value') selecionado!: Usuario
	@Prop({ type: Boolean, default: false }) filtrarSomenteUsuariosAtivos!: boolean
	@Prop({ type: Boolean, default: false }) filtrarSomenteVendedorAtivo!: boolean

	busca = ''
	findUsuarioUseCase = new FindUsuarioUseCase()
	page = 1
	totalPages = 1
	usuarios: Usuario[] = []
	cancelToken: CancelTokenSource | null = null
	loading = false

	async created() {
		await this.buscar()
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.loading = true
			this.cancelToken = axios.CancelToken.source()

			const pageUsuarios = await this.findUsuarioUseCase.findAllVendedores({
				page: this.busca ? -1 : (this.page -1 || 0),
				size: 3,
				nome: this.busca || undefined,
				somenteVendedor: this.filtrarSomenteVendedorAtivo,
				somenteUsuariosAtivos: this.filtrarSomenteUsuariosAtivos,
				axiosConfig: {
					cancelToken: this.cancelToken.token,
				},
			}) 
			this.usuarios = pageUsuarios.content
			this.totalPages = pageUsuarios.totalPages
		}catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.loading = false
		}
	}

	@Watch('page')
	onChangePage = this.buscar

	@Watch('busca')
	onChangeBusca() {
		this.totalPages = 0
		this.buscar()
	}
}
