import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { MetaServiceAdapter } from './adapter'

export class DeleteMetaUseCase {
	@Inject('MetaServiceAdapter')
	private service!: MetaServiceAdapter

	delete = async (id: string): Promise<void> => await this.service.remove(id)

}